<template>
  <div>
    <div class="banner" :style="cover">
      <h1 class="banner-title animated fadeInDown">{{ categoryName }}</h1>
    </div>
    <div class="article-list-wrapper">
      <el-empty v-if="articleList.length === 0" description="暂无数据..."></el-empty>
      <v-row>
        <v-col md="4" cols="12" v-for="item of articleList" :key="item.articleId">
          <!-- 文章 -->
          <v-card class="animated zoomIn article-item-card">
            <div class="article-item-cover">
              <router-link :to="'/articles/' + item.articleId" tag="a" target="_blank">
                <!-- 缩略图 -->
                <v-img class="on-hover" width="100%" height="100%" :src="item.cover"/>
              </router-link>
            </div>
            <div class="article-item-info">
              <!-- 文章标题 -->
              <div>
<!--                作者：<strong>{{ item.authorName }}</strong> |-->
                <router-link :to="'/articles/' + item.articleId" tag="a" target="_blank">
                  <strong><div class="showInline">{{ item.title }}</div></strong>
                </router-link>
              </div>
              <div style="margin-top:0.375rem">
                <!-- 发表时间 -->
                <v-icon size="20">mdi-clock-outline</v-icon>
                {{ item.createTime | date }} |
                <!-- 文章标签 -->
                <v-icon>mdi-bookmark</v-icon>{{item.labelName}}
              </div>
            </div>
            <!-- 分割线 -->
            <v-divider></v-divider>

          </v-card>
        </v-col>
      </v-row>

      <!-- 分页按钮 -->
      <v-pagination v-if="count > 0"
                    color="#00C4B6"
                    v-model="current"
                    :length="Math.ceil(count / param.pageSize)"
                    total-visible="7" style="margin-top: 20px;"
      />

    </div>
  </div>
</template>

<script>
import { categoryInfo, getArticlesByCategoryId } from "@/api/article.js";
export default {
  created() {
    this.getCategoryInfo();
    this.fetchArticle();
  },
  data: function() {
    return {
      current: 1,
      img: process.env.VUE_APP_IMG_API,
      size: 9,
      articleList: [],
      name: "",
      title: "",
      count: 0,
      categoryName: "",
      param: {
        pageNo: 1,
        pageSize: 9,
        total: 0,
        categoryId: ""
      }
    };
  },
  methods: {
    // 获取分类信息
    getCategoryInfo() {
      categoryInfo(this.$route.params.categoryId).then(res => {
        if (res.code === 200) {
          this.categoryName = res.data.categoryName;
        }
      })
    },

    // 根据分类ID获取文章列表
    fetchArticle() {
      this.param.categoryId = this.$route.params.categoryId;
      this.param.pageNo = this.current;
      getArticlesByCategoryId(this.param).then(res => {
        if (res.code === 200) {
          this.articleList = res.data.list;
          this.count = res.data.total;
        }
      })
    },

  },
  watch: {
    current(value) {
      this.current = value;
      this.fetchArticle();
    }
  },
  computed: {
    cover() {
      const cover =
        "https://98k-hk666.oss-cn-shanghai.aliyuncs.com/iblog/img/admin/003300-1659717180bac2.jpg?versionId=CAEQQRiBgMC45fL4lRgiIGFlZjk5MzE1OTM3ZjRkOThiZjMzOTI4NjJiMjYwMTMy";
      return "background: url(" + cover + ") center center / cover no-repeat";
    }
  }
};
</script>

<style scoped>
@media (min-width: 760px) {
  .article-list-wrapper {
    max-width: 1106px;
    margin: 370px auto 1rem auto;
  }
  .article-item-card:hover {
    transition: all 0.3s;
    box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
  }
  .article-item-card:not(:hover) {
    transition: all 0.3s;
  }
  .article-item-card:hover .on-hover {
    transition: all 0.6s;
    transform: scale(1.1);
  }
  .article-item-card:not(:hover) .on-hover {
    transition: all 0.6s;
  }
  .article-item-info {
    line-height: 1.7;
    padding: 15px 15px 12px 18px;
    font-size: 15px;
  }
}
@media (max-width: 759px) {
  .article-list-wrapper {
    margin-top: 230px;
    padding: 0 12px;
  }
  .article-item-info {
    line-height: 1.7;
    padding: 15px 15px 12px 18px;
  }
}
.article-item-card {
  border-radius: 8px !important;
  box-shadow: 0 4px 8px 6px rgba(7, 17, 27, 0.06);
}
.article-item-card a {
  transition: all 0.3s;
}
.article-item-cover {
  height: 220px;
  overflow: hidden;
}
.article-item-card a:hover {
  color: #8e8cd8;
}
.tag-wrapper {
  padding: 10px 15px 10px 18px;
}
.tag-wrapper a {
  color: #fff !important;
}
.tag-btn {
  display: inline-block;
  font-size: 0.725rem;
  line-height: 22px;
  height: 22px;
  border-radius: 10px;
  padding: 0 12px !important;
  background: linear-gradient(to right, #bf4643 0%, #6c9d8f 100%);
  opacity: 0.6;
  margin-right: 0.5rem;
}
.showInline {
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}
</style>
